<div class="example-container">
  <div class="container">
    <h1>Welcome to Synlesa!</h1>
    <form class="example-form" [formGroup]="loginForm" (ngSubmit)="onFormSubmit(loginForm.value)">
      <h2 id="text-container">Please login with your name</h2>
      <mat-form-field class="example-full-width" id="username">
        <mat-label>Name</mat-label>
        <input class="input" matInput placeholder="Enter your nickname" formControlName="nickname"
          [errorStateMatcher]="matcher" maxlength="25">
        <mat-error>
          <span *ngIf="!loginForm.get('nickname').valid && loginForm.get('nickname').touched">Please enter your
            name</span>
        </mat-error>
      </mat-form-field>
      <div class="button-row">
        <button id="login-btn" type="submit" title="login" [disabled]="!loginForm.valid" mat-fab color="primary">
          <mat-icon>login</mat-icon>
        </button>
      </div>
    </form>
    <form class="example-form"e id="example-form-password" [formGroup]="passwordForm" (ngSubmit)="onPasswordFormSubmit(passwordForm.value)">
      <mat-form-field class="example-full-width">
        <mat-label>Password</mat-label>
        <input matInput placeholder="Enter your password" formControlName="password" [errorStateMatcher]="matcher"
          maxlength="25">
        <mat-error>
          <span *ngIf="!passwordForm.get('password').valid && passwordForm.get('password').touched">Please enter your
            password</span>
        </mat-error>
      </mat-form-field>
      <div class="button-row">
        <button type="submit" title="passowrd" [disabled]="!passwordForm.valid" mat-fab color="primary">
          <mat-icon>login</mat-icon>
        </button>
      </div>
    </form>
    <br>
    <div id="text-container">
      <p id="instructions"> Please login with your name. Click the book you
        are reading and answer questions!</p>
      <br>
      <br>
      <img src="assets\big-read-lakeshore-logo-white-text.png" alt="Big read logo">
    </div>
    <div id="links">
      <a routerLink="/about" id="about-btn">About Synlesa</a><br>
      <a href="https://docs.google.com/document/d/1A5babxzKJgGzU8S8NHVthbERQli1otx_5DOeKoXqLAQ/edit?usp=sharing" id="help-link">Synlesa Help</a>
    </div>
   
  </div>
  
</div>
