<div id="change-password-popup">
  <form id="change-form" class="mat-elevation-z8" (ngSubmit)="changeInstructionsSubmit(changeInstructions.value)"
    [formGroup]="changeInstructions">
    <h3>Enter new instructions</h3>
    <input placeholder="new instructions" formControlName="instructions"><br>
    <div id="change-popup-button-container">
      <input type="submit" class="change-popup-button" value="Submit">
      <button type="close" class="change-popup-button" (click)="closeChangePopup()">Close</button><br><br>
    </div>

  </form>
</div>

<div class="example-container mat-elevation-z8">
  <mat-drawer-container class="drawer-container">
    <mat-drawer-content (click)="toggleTopDrawer()" id="top-drawer" class="top-drawer mat-elevation-z8">
      <div id="left-header-content">
        <h1>{{dispRoomname}}</h1>
        <div id="user-list">
          Users in room: {{usernames}}
        </div>
        <p id="instructions">{{room.instructions}}</p>
        <button id="edit-button" (click)="displayInstPopup()"
          *ngIf="(nickname.match('admin') || nickname.match('Admin'))">Edit Instructions</button>
      </div>
      <div id="right-header-content">
        <div class="delete-msg-buttons">
          <button *ngIf="(nickname.match('admin') || nickname.match('Admin')) && !book.isArchived" class="delete-btn"
            (click)="deleteMessages()">Delete Selected</button>
          <button id="logout" type="button" title="exit room" mat-button matSuffix mat-icon-button aria-label="Exit"
            (click)="exitChat()" class="exit-button">
            <mat-icon>logout</mat-icon>
          </button>
          <button *ngIf="(nickname.match('admin') || nickname.match('Admin')) && !book.isArchived" type="button"
            class="clear-btn" (click)="openPopup()">Clear All</button>
          <button class="admin-button" *ngIf="nickname.match('admin') || nickname.match('Admin')" mat-flat-button
            title="adminPage" (click)="clickAdmin()">Admin</button>
        </div>
        <div id="expand-display">
          <span class="expand-display-info" id="plus">+</span>
          <span class="expand-display-info" id="minus">-</span>
        </div>
      </div>
      <div class="popup mat-elevation-z8" id="popup">
        <p>Are you sure you want to clear all messages in "{{dispRoomname}}"?</p>
        <div class="btn-group">
          <button type="button" id="clear" (click)="clearMessages()">Yes</button>
          <button id="close" (click)="closePopup()">No</button>
        </div>
      </div>
    </mat-drawer-content>
    <mat-drawer-content class="chat-pane">

      <div id="mySidebar" class="sidebar">
        <table mat-table [dataSource]="rooms" class="example-table" matSort matSortActive="roomname" matSortDisableClear
          matSortDirection="asc">
          <ng-container matColumnDef="roomname">
            <th mat-header-cell *matHeaderCellDef>Questions</th>
            <td class="room-name" mat-cell *matCellDef="let row">
              {{row.dispRoomname}}
            </td>
          </ng-container>
          <div class="question-table">
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr id="room-button" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="clickRoomRow(row.roomname)
                "></tr>
          </div>
        </table>
      </div>

      <div id="nav-open-button">
        <div *ngIf="sideBarOpen">
          <button class="openbtn" (click)="closeNav()">&#9776;</button>
        </div>
        <div *ngIf="!sideBarOpen">
          <button class="openbtn" (click)="openNav()">&#9776;</button>
        </div>
      </div>

      <div #chatcontent [scrollTop]="scrollDown()" id="chat-content">
        <div class="message-box" *ngFor="let chat of chats">
          <div class="chat-status" text-center *ngIf="chat.type==='join'||chat.type==='exit';else message">
            <span class="chat-date">{{chat.date | date:'short'}}</span>
            <span class="chat-content-center">{{chat.message}}</span>
          </div>
          <ng-template #message>
            <div class="chat-message">
              <div class="right-bubble"
                [ngClass]="{'right-bubble': chat.nickname === nickname, 'left-bubble': chat.nickname !== nickname}">
                <span class="msg-name" *ngIf="chat.nickname === nickname">Me</span>
                <span class="msg-name" *ngIf="chat.nickname !== nickname">{{chat.nickname}}</span>
                <span class="msg-date"> at {{chat.date | date:'short'}}</span>
                <p text-wrap *ngIf="chat.type=='text'">{{chat.message}}</p>
                <a href={{chat.mediaURL}}>
                  <div *ngIf="chat.type=='image'">
                    <img class="chat-image" src={{chat.mediaURL}}>
                  </div>
                  <div *ngIf="chat.type=='video'">
                    <video class="chat-vid" controls src={{chat.mediaURL}}></video>
                  </div>
                </a>
                <div *ngIf="chat.type=='audio'">
                  <audio class="chat-audio" controls src={{chat.mediaURL}}></audio>
                </div>
                <input *ngIf="(nickname.match('admin') || nickname.match('Admin')) && !book.isArchived" type="checkbox"
                  id="chat-check-box" name="check-box" (click)="toggleCheckBox(chat.key)">
                <button *ngIf="!book.isArchived" class="reply-btn"
                  (click)="reply(chat.nickname, chat.message, chat.date)">&#8617;</button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </mat-drawer-content>
    <mat-drawer-content class="footer-drawer">
      <footer class="sticky-footer mat-elevation-z8">

        <p class="no-message" *ngIf="book.isArchived; else other">Book is archived, you may no longer send chats.</p>
        <ng-template #other>
          <p class="no-message" *ngIf="nickname.match('view-only')">You are in view only mode, please log in to send chats.</p>
        </ng-template>



        <div *ngIf="!nickname.match('view-only') && !book.isArchived">
          <div class="footer-div">
            <div id="reply-header">
              <span id="reply-msg">Reply to {{replyName}}'s post: {{replyMessage}}</span>
              <button id="reply-exit" (click)="exitReply()">x</button>
            </div>
            <form class="message-form " [formGroup]="chatForm" (ngSubmit)="onFormSubmit(chatForm.value)">
              <mat-form-field class="message-form-field">
                <input matInput formControlName="message" [errorStateMatcher]="matcher" autofocus>
                <button type="submit" [disabled]="!chatForm.valid" mat-button matSuffix mat-icon-button
                  aria-label="Send">
                  <mat-icon>send</mat-icon>
                </button>
              </mat-form-field>
            </form>
          </div>
          <div class="footer-btns" *ngIf="!nickname.match('view-only')" c9RU6yVgdDErZ2YwtDR7>
            <input hidden type="file" id="media-select" name="filename" (change)="upload($event)"
              formControlName="audio" accept=".mp3, .m4a,.x-m4a, .mpeg, .jpg, .png, .jpeg, .HEIC, .MOV, .mp4">
            <button id="upload-media-btn" onclick="document.getElementById('media-select').click()">Upload image, video,
              or audio</button>
            <button id="print-btn" (click)="goToPrintList()">Print Messages</button>
          </div>
        </div>

      </footer>
    </mat-drawer-content>
  </mat-drawer-container>
</div>