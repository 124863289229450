import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { firebase } from '../app.component';
import { Router } from '@angular/router';

export const snapshotToArray = (snapshot: any) => {
  const returnArr = [];

  snapshot.forEach((childSnapshot: any) => {
    console.log("in snapshot");
    const item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};

@Component({
  selector: 'app-printchats',
  templateUrl: './printchats.component.html',
  styleUrls: ['./printchats.component.css']
})

export class PrintchatsComponent implements OnInit {

  name = "Chat List";
  chats = [];
  bookname = '';
  roomname = '';

  constructor(private router: Router) { 
    this.bookname = sessionStorage.getItem("bookname");
    console.log("book: " + this.bookname)
    this.roomname = sessionStorage.getItem("chatname");
    console.log("question: " + this.roomname);
    
    firebase.database().ref(`chats/${this.bookname}/${this.roomname}`).once('value').then(snapshot => {
      snapshot.forEach(snap => {
        this.chats.push(snap.val())
      })
    })
  }

  ngOnInit(): void {
  }

  goBack()
  {
    this.router.navigate([this.bookname, this.roomname])
  }

}
