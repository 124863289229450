<div class="example-container">
    <button mat-flat-button id="return-btn" title="logout" (click)="goBack()">
        <mat-icon>logout</mat-icon>
      </button>
    <div id="print-section" class="print-section mat-elevation-z8">
        <h2 id="header"> Messages: </h2>
        <div *ngFor="let chat of chats">
            <span id="messages">
                <strong><u id="user">{{chat.nickname}}:</u></strong> 
                <span *ngIf="chat.type=='image'">
                    <img class="chat-image" src={{chat.mediaURL}}>
                </span>
                <span *ngIf="chat.type=='video'">
                  *posted a video*
                </span>
                <span *ngIf="chat.type=='audio'">
                  *posted an audio recording*
                </span>
                <span *ngIf="chat.type=='text'">
                    {{chat.message}}
                </span>
            </span>
            <br>
            <br>
        </div>
    </div>
    <button class="print" printTitle={{name}} styleSheetFile="printchats.component.css" printSectionId="print-section" ngxPrint>Print</button>
</div>
