import { Component } from '@angular/core';
//import { getStorage } from 'firebase/storage'
import fb from 'firebase';
//import { AngularFireStorage } from '@angular/fire/storage'

const config = {
  //apiKey: 'YOUR_API_KEY',
  /*databaseURL: "https://angularchat-c11b7-default-rtdb.firebaseio.com"*/
  databaseURL: "https://synlesa-default-rtdb.firebaseio.com/",
  storageBucket: "gs://synlesa.appspot.com"
};

export const firebase = !fb.apps.length ? fb.initializeApp(config) : fb.app();
//export const storage: AngularFireStorage;
//export const storage = getStorage(firebase);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'angular-chat';
  firebase;

  constructor() {
    // I used code from https://stackoverflow.com/questions/43331011/firebase-app-named-default-already-exists-app-duplicate-app
    // I still need to figure out the best way to give credit.
    if(!fb.apps.length){
      this.firebase = fb.initializeApp(config);
    }else{
      this.firebase = fb.app();
    }
  }
}