<div class="example-container mat-elevation-z8">
  <meta name="viewport" content="width=device-width initial-scale=1.0">
  <h3>
    <button id="exit-btn" mat-flat-button title="logout" (click)="toBookList()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    &nbsp;{{nickname}}
  </h3>
  <h2>
    Admin functions
  </h2>
  <hr>
  <div (click)="toggleHeader('ex-DSB', 'col-DSB', 'func-DSB')" class="func-header">
    <label class="div-label">Delete Selected Books</label>
    <label class="div-label" class="expand-button" id="ex-DSB">+</label>
    <label class="div-label" class="collapse-button" id="col-DSB">-</label>
  </div>
  <div class="function-div" id="func-DSB">
    <form (ngSubmit)="deleteBooks(bookIds)">
      <ul>
        <li class="no-content" *ngIf="books.length == 0">No books created yet</li>
        <li *ngFor="let book of books">
          <input type="checkBox" (click)="toggleCheckedBookDel(book)">
          {{book.dispBookname}}
        </li>
      </ul>
      <input type="submit" class="delete-button" value="Delete">
    </form>
  </div>
  <hr>
  <div (click)="toggleHeader('ex-DSR', 'col-DSR', 'func-DSR')" class="func-header">
    <label class="div-label">Delete Selected Questions</label>
    <label class="div-label" class="expand-button" id="ex-DSR">+</label>
    <label class="div-label" class="collapse-button" id="col-DSR">-</label>
  </div>
  <div class="function-div" id="func-DSR">
    <form (ngSubmit)="deleteRooms()">
      <ul>
        <li class="no-content" *ngIf="books.length == 0">No books created yet</li>
        <li id="book-in-delete-rooms" *ngFor="let book of books">

          <div (click)="toggleHeader('ex-' + book.bookname, 'col-' + book.bookname, 'room-list-' + book.bookname)">
            <span class="headers-for-lists">{{book.dispBookname}}</span>
            <span class="headers-for-lists" id="ex-{{book.bookname}}">&nbsp;+</span>
            <span class="headers-for-lists minus" id="col-{{book.bookname}}">&nbsp;-</span>
          </div>


          <ul class="room-list" id="room-list-{{book.bookname}}">
            <li *ngIf="roomMap.get(book.bookname) == 0">
              <span class="no-content">No rooms for {{book.bookname}}</span>
            </li>
            <li *ngFor="let room of roomMap.get(book.bookname);let i=index">
              <input type="checkBox" (click)="toggleCheckedRoom(room, book)">
              <span id="delete-room-roomname">{{room.dispRoomname}}</span>
              <br>
              <br *ngIf="i != (roomMap.get(book.bookname).length - 1)">
            </li>
          </ul>
        </li>
        <li>
          <span class="headers-for-lists" (click)="toggleHeader('ex-meta', 'col-meta',
          'room-list-Meta')">Meta Rooms </span>
          <span class="headers-for-lists" id="ex-meta">&nbsp;+</span>
          <span class="headers-for-lists" class="minus" id="col-meta">&nbsp;-</span>
          <ul class="meta-room-ul" id="room-list-Meta">
            <li class="meta-room-li" *ngIf="metaRooms.length == 0">
              <span class="no-content">No meta rooms yet</span>
            </li>
            <li class="meta-room-li" *ngFor="let room of metaRooms">
              <input type="checkBox" (click)="toggleCheckedMetaRoom(room)">
              <span class="delete-room-roomname">{{room.roomname}}</span>
              <br>
              <br *ngIf="i != (metaRooms.length - 1)">
            </li>
          </ul>
        </li>
      </ul>
      <input type="submit" value="Delete">
    </form>
  </div>
  <hr>
  <div (click)="toggleHeader('ex-ASBTA', 'col-ASBTA', 'func-ASBTA')" class="func-header">
    <label class="div-label">Add Selected Books to Archive</label>
    <label class="div-label" class="expand-button" id="ex-ASBTA">+</label>
    <label class="div-label" class="collapse-button" id="col-ASBTA">-</label>
  </div>
  <div class="function-div" id="func-ASBTA">
    <form id="arch-form" [formGroup]="selectArchForm" (ngSubmit)="addToArchive(selectArchForm.value)">
      <select formControlName="archiveName" id="archive-select"></select>
      <ul>
        <li class="no-content" *ngIf="books.length == 0">No books created yet</li>
        <li *ngFor="let book of books">
          <input type="checkbox" (click)="toggleArchBook(book)">
          {{book.dispBookname}}
        </li>
      </ul>
      <input type="submit">
    </form>
  </div>
  <hr>
  <div (click)="toggleHeader('ex-AANA', 'col-AANA', 'func-AANA')" class="func-header">
    <label class="div-label">Add a New Archive</label>
    <label class="div-label" class="expand-button" id="ex-AANA">+</label>
    <label class="div-label" class="collapse-button" id="col-AANA">-</label>
  </div>
  <div class="function-div" id="func-AANA">
    <form id="add-new-ar-form" [formGroup]="addNewArch" (ngSubmit)="addNewArchive(addNewArch.value)">
      <input formControlName="archiveName" placeholder="New archive name">
      <br>
      <input type="submit">
    </form>
  </div>
  <hr>
  <div (click)="toggleHeader('ex-SATD', 'col-SATD', 'func-SATD')" class="func-header">
    <label class="div-label">Select Archives to Delete</label>
    <label class="div-label" class="expand-button" id="ex-SATD">+</label>
    <label class="div-label" class="collapse-button" id="col-SATD">-</label>
  </div>
  <div class="function-div" id="func-SATD">
    <form [formGroup]="deleteArch" (ngSubmit)="deleteArchives()">
      <ul>
        <li class="no-content" *ngIf="archives.length == 0">
          No archives created yet
        </li>
        <li *ngFor="let ar of archives">
          <input formControlName="archivesToDelete" type="checkbox" (click)="toggleArchive(ar)">
          {{ar.val()}}
        </li>
      </ul>
      <input type="submit" value="Delete">
    </form>
  </div>
  <hr>
  <div (click)="toggleHeader('ex-USB', 'col-USB', 'func-USB')" class="func-header">
    <label class="div-label">Unarchive Selected Books</label>
    <label class="div-label" class="expand-button" id="ex-USB">+</label>
    <label class="div-label" class="collapse-button" id="col-USB">-</label>
  </div>
  <div class="function-div" id="func-USB">
    <form (ngSubmit)="unArchiveBooks()">
      <ul>
        <li class="no-content" *ngIf="archives.length == 0">No archives created yet</li>
        <li *ngFor="let ar of archives">
          <div (click)="toggleHeader('ex-archive-list-' + ar.val(), 'col-room-list-' + ar.val(), 'archive-list-' + ar.val())">
            <span class="headers-for-lists">{{ar.val()}}</span>
            <span class="headers-for-lists" id="ex-archive-list-{{ar.val()}}">&nbsp;+</span>
            <span class="headers-for-lists minus" id="col-room-list-{{ar.val()}}">&nbsp;-</span>
          </div>
          <ul class="book-list" id="archive-list-{{ar.val()}}">
            <li class="no-content" *ngIf="getBooksInArchive(ar.val()) == 0">No books in {{ar.val()}} yet</li>
            <li *ngFor="let book of getBooksInArchive(ar.val())">
              <input type="checkbox" (click)="toggleUnarchiveBooks(book)">
              {{book.dispBookname}}
            </li>
          </ul>
        </li>
      </ul>
      <input type="submit" value="Unarchive">
    </form>
  </div>
  <hr>
  <div (click)="toggleHeader('ex-CP', 'col-CP', 'func-CP')" class="func-header">
    <label class="div-label">Change Password</label>
    <label class="div-label" class="expand-button" id="ex-CP">+</label>
    <label class="div-label" class="collapse-button" id="col-CP">-</label>
  </div>
  <div class="function-div" id="func-CP">
    <div class="psw-div">
      <form id="psw-form" [formGroup]="pswForm" (ngSubmit)="changePassword(pswForm.value)">
        <div class="psw-form-div">
          <label class="psw-label">Current password</label><br>
          <input formControlName="oldPassword" class="psw-input" placeholder="Current password"><br>
        </div>
        <div class="psw-form-div">
          <label class="psw-label">New password</label><br>
          <input formControlName="newPassword" class="psw-input" placeholder="New password"><br>
        </div>
        <div class="psw-form-div">
          <label class="psw-label">Confirm new password</label><br>
          <input formControlName="newPasswordConf" class="psw-input" placeholder="Confirm new password"><br>
        </div>
        <input id="psw-submit" type="submit" value="Submit">
      </form>
    </div>
  </div>
  <hr>

  <div id="help-container">
    <a href="https://docs.google.com/document/d/1SCKeLATPVFk8I2hbO8kJJEpva1Sev79dvC2JDO8HeOw/edit?usp=sharing"
      id="help-link">Admin Help</a>
  </div>
</div>