<div class="example-container">
    <button mat-flat-button id="logout-btn" title="logout" (click)="goToLogin()">
        <mat-icon>logout</mat-icon>
      </button>

    <p class="title">About Synlesa</p>
    
    <p class="note1"> We chose the name Synlesa because lesa means to read in Icelandic and
        we thought it sounded pretty and Syn means coming together so it Synlesa symbolizes coming together to read</p>
    <div class="line"></div>
    <h1>Acknowledgments</h1>
    <p class="note"> This webapp has been created due to the many hands that have put in great work to make this possible. We specifically would like to thank:</p>
    <ul class="ack">
        <li class="ack">Dr. Michael Jipping & Dr. Deb VanDuinen</li>
        <li class="ack">Computer Science Student Researchers: Luke Van Hout, Tatiana Beran, Quentin Couvelaire, Josiah Brett, Brendan Murphy</li>
        <li class="ack">Hope College Computer Science Department</li>
        <li class="ack">Hope College Education Department</li>
    </ul>
    <h3> Happy Reading! </h3>
</div>
<br>