<div id="change-password-popup">
  <form id="change-form" class="mat-elevation-z8" (ngSubmit)="changeTitleSubmit(changeTitle.value)"
    [formGroup]="changeTitle">
    <h3>Enter new title</h3>
    <input placeholder="new title" formControlName="title"><br>
    <div id="change-popup-button-container">
      <input type="submit" class="change-popup-button" value="Submit">
      <button type="close" class="change-popup-button" (click)="closeChangePopup()">Close</button><br><br>
    </div>

  </form>
</div>
<div id="wrapper"></div>
<div class="example-container mat-elevation-z8" id="body">
  <meta name="viewport" content="width=device-width initial-scale=1.0">
  <p id="easter-egg" (click)="purpleMode()">.</p>
  <h3 id="header">
    <span>
      <button id="logout-button" mat-flat-button title="logout" (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button>
      &nbsp;{{nickname}}
    </span>
  </h3>
  <div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <h2 class="book-list-title" id="title"> {{title}} </h2>
  <div class="button-row">
    <div id="second-row" *ngIf="nickname.match('admin') || nickname.match('Admin')">
      
        <button id="edit-button" (click)="displayInstPopup()"
          *ngIf="nickname.match('admin') || nickname.match('Admin')">Edit Title</button>
      </div>
    <button id="add-button" *ngIf="nickname.match('admin') || nickname.match('Admin')" mat-flat-button color="primary" title="add book"
      [routerLink]="['/addbook']">
      <mat-icon>add</mat-icon>
    </button>
    <button class="admin-button" id="admin-button" *ngIf="nickname.match('admin') || nickname.match('Admin')" mat-flat-button
      title="adminPage" (click)="clickAdmin()"> Admin Page</button>
  </div>
  <div id="book-list-container" *ngIf="(!nickname.match('admin') && !nickname.match('Admin'))">
    <span *ngIf="regBooks.length == 0" class="no-content">No books created yet</span>
    <button id="book-btn" class="book-btn" *ngFor="let book of regBooks"  class="mat-elevation-z8" (click)="clickBook(book.bookname)">
      <div id="book-image-container">
        <img class="book-image mat-elevation-z8" src="{{book.imageUrl}}">
      </div>
      <div id="book-info-container">
        <p id="bookname">{{book.dispBookname}}</p>
        <p id="author">By: {{book.author}}</p>
      </div>
    </button>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
  </div>
  <div id="book-list-container" *ngIf="(nickname.match('admin') || nickname.match('Admin'))">
    <span *ngIf="regBooks.length == 0" class="no-content">No books created yet</span>
    <button id="book-btn" *ngFor="let book of regBooks; let index = index;"  class="mat-elevation-z8" (click)="clickBook(book.bookname)"
          draggable="true" (dragstart)="onDragStart(index)" (dragenter)="onDragEnter(index)" (dragend)="onDragEnd()">
      <div id="book-image-container">
        <img class="book-image mat-elevation-z8" src="{{book.imageUrl}}">
      </div>
      <div id="book-info-container">
        <p id="bookname">{{book.dispBookname}}</p>
        <p id="author">By: {{book.author}}</p>
      </div>
    </button>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
      <span id="book-btns"></span>
  </div>
  <br>
  <hr>
  <h2 id="archives-header">Archives</h2>
  <ul>
    <li class="no-content" *ngIf="archives.length == 0">No archives created yet</li>
    <li class="archive-block" *ngFor="let arch of archives">
      <button type="button" class="expand-button" id="ex-archive-list-{{arch}}"
            (click)="expand('ex-archive-list-' + arch, 'col-room-list-' + arch, 'archive-list-' + arch)">{{arch}}&nbsp;+</button>
          <button type="button" class="collapse-button" id="col-room-list-{{arch}}"
            (click)="collapse('ex-archive-list-' + arch, 'col-room-list-' + arch, 'archive-list-' + arch)">{{arch}}&nbsp;-</button>
      <ul class="book-list" id="archive-list-{{arch}}">
        <li class="no-content" *ngIf="getArchiveBooks(arch) == 0">No books in {{arch}} yet</li>
        <li id="archive-book" (click)="clickBook(book.bookname)" *ngFor="let book of getArchiveBooks(arch)">
          {{book.bookname}}
        </li>
      </ul>
    </li>
  </ul>
</div>