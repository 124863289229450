import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DataService } from '../data.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-add-book',
  templateUrl: './add-book.component.html',
  styleUrls: ['./add-book.component.css']
})
export class AddBookComponent implements OnInit {
  bookForm: FormGroup;
  pictureUploaded = false;
  matcher = new MyErrorStateMatcher();
  isLoadingResults = false;
  imageUrl = '';

  constructor(private router: Router,
    private formBuilder: FormBuilder) {
      if(localStorage.getItem('nickname') != null){
      }
      else{
        localStorage.setItem('nickname', 'view-only');
      }
  }

  ngOnInit(): void {
    this.bookForm = this.formBuilder.group({
      'bookname': [null, Validators.required],
      'image': [null, Validators.required],
      'author': [null, Validators.required]
    });
  }

  toBookList(): void {
    this.router.navigate(['/booklist']);
  }

  onFormSubmit(form: any) {
    const book = form;
    DataService.addBook(book.bookname, book.author, this.imageUrl);
    this.router.navigate(['/booklist']);
  }

  upload(event: Event, form: any) {
    this.isLoadingResults = true;
    DataService.uploadImage(form.bookname, event).then(vari => {
      this.imageUrl = vari;
      this.isLoadingResults = false;
      this.pictureUploaded = true;
    });
  }
}
