<div class="example-container">
    <div id="container">
        <button mat-flat-button title="logout" (click)="toBookList()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <form class="example-form" [formGroup]="bookForm" (ngSubmit)="onFormSubmit(bookForm.value)">
            <h2>Please Enter New Book</h2>
            <mat-form-field class="example-full-width">
                <mat-label class="input-label">Book title</mat-label>
                <input id="book-input" matInput placeholder="Enter book name" formControlName="bookname"
                    [errorStateMatcher]="matcher">
                <mat-error>
                    <span *ngIf="!bookForm.get('bookname').valid && bookForm.get('bookname').touched">Enter book name</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label class="input-label">Author</mat-label>
                <input id="author-input" matInput placeholder="Enter Author's Name" formControlName="author"
                    [errorStateMatcher]="matcher">
                <mat-error>
                    <span *ngIf="!bookForm.get('author').valid && bookForm.get('author').touched">Enter author</span>
                </mat-error>
            </mat-form-field>
            <div class="choose-image">
                <label for="filename" id="image-select-label">Choose an image:&nbsp;&nbsp;</label>
                <input type="file" id="image-select" name="filename" (change)="upload($event, bookForm.value)"
                    formControlName="image" accept=".jpg,.png,.HEIC,.jpeg" title="" />
            </div>
            <div class="button-row">
                <button id="submit-button" type="submit" title="add book" [disabled]="!bookForm.valid" mat-fab
                    color="primary">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </form>
    </div>
</div>
