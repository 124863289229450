import { getUrlScheme, isNgTemplate, MessageBundle } from '@angular/compiler';
import { Injectable, Query } from '@angular/core';
import { discardPeriodicTasks } from '@angular/core/testing';
import { firebase } from './app.component';
import { snapshotToArray } from './chatroom/chatroom.component';
import { v4 as uuidv4 } from 'uuid';
import { url } from 'inspector';
import { ITS_JUST_ANGULAR } from '@angular/core/src/r3_symbols';

const storage = firebase.storage('gs://synlesa.appspot.com');

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor() {
  }

  private static snapshotToArray(snapshot: any) {
    const returnArr = [];
    snapshot.forEach((childSnapshot: any) => {
      const item = childSnapshot.val();
      item.key = childSnapshot.key;
      returnArr.push(item);
    });

    return returnArr;
  };

  //Handling Book Data ---------------------------------------------------------------------------------

  public static getAllBooks() {
    return firebase.database().ref('books').once('value');
  }

  public static getBook(bookname: string) {
    return firebase.database().ref(`books/`).orderByChild('bookname').equalTo(bookname).once('value');
  }

  public static addBook(bookname: string, author: string, imageUrl: string) {
    var dispBookname = bookname
    for(let i = 0; i < bookname.length; i++ ){
      bookname = bookname.replace('.', '%2X');
      bookname = bookname.replace('#', '%2Z');
    }
    
    const reference = firebase.database().ref('books/').push();
    reference.set({ bookname, dispBookname, imageUrl, author, isArchived: false, archive: '' });
  }

  public static async uploadImage(bookname, event) {
    var filePath = `bookimage/${bookname}/${uuidv4()}`;
    const storageRef = firebase.storage().ref();
    var imageUrl = '';

    await storageRef.child(filePath).put((event.target as HTMLInputElement).files[0]);
    await storageRef.child(filePath).getDownloadURL().then((url: string) => {
      imageUrl = url;
    }).catch(function (error) {
      alert(error);
    })
    return imageUrl;
  }

  public static deleteBook(roomId: string[], roomnames: string[], books: any[]) {
    var bookIds = books.map(getId);
    function getId(item) {
      return item.key
    }
    var booknames = books.map(getName);
    function getName(item) {
      return item.bookname
    }
    var bookImageUrls = books.map(getMessageUrl);
    function getMessageUrl(item) {
      return item.imageUrl
    }
    for (let i = 0; i < bookIds.length; i++) {
      firebase.database().ref(`books/${bookIds[i]}`).remove().then(function () {
      })
        .catch(function (error) {
        });

      firebase.storage().refFromURL(bookImageUrls[i]).delete();
      firebase.database().ref(`rooms/${booknames[i]}/`).remove().then(function () {
      })
        .catch(function (error) {
        });
      for (let j = 0; j < roomnames.length; j++) {
        var chats = [];
        DataService.getChatsInRoom(roomnames[j], booknames[i]).then(snapshot => {
          chats = [];
          chats = snapshotToArray(snapshot);
          var chatMediaUrls = chats.map(getMediaURL);
          function getMediaURL(item) {
            return item.mediaURL;
          }
          var chatTypes = chats.map(getType);
          function getType(item) {
            return item.type;
          }
          for (let k = 0; k < chatMediaUrls.length; k++) {
            if (chatTypes[k] == 'image' || chatTypes[k] == 'video' || chatTypes[k] == 'audio') {
              firebase.storage().refFromURL(chatMediaUrls[k]).delete();
            }
          }
          firebase.database().ref(`chats/${booknames[i]}/`).remove().then(function () {
          })
            .catch(function (error) {
            });
        })
      }
    }
  }

  //Handling Room Data ---------------------------------------------------------------------------------

  public static getAllRooms(bookname: string) {
    return firebase.database().ref(`rooms/${bookname}`).once('value');
  }

  public static getMetaRooms() {
    return firebase.database().ref('rooms/Meta').once('value');
  }

  public static getRoom(roomname: string, bookname: string) {
    return firebase.database().ref(`rooms/${bookname}`).orderByChild('roomname').equalTo(roomname).once('value');
  }

  public static addRoom(roomname: string, bookname: string, ifMeta: boolean, instruction: string) {
    var reference;
    var instr = "Answer the question and feel free to submit a photo or video too!"
    var dispRoomname = roomname;
    for(let i = 0; i < roomname.length; i++){
      roomname = roomname.replace('.', '%2X');
      roomname = roomname.replace('#', '%2Z');
    }
    
    if (instruction != null) {
      instr = instruction;
    }
    if (ifMeta) {
      firebase.database().ref(`rooms/Meta`).once('value').then(snapshot => {
        var found = false
        snapshot.forEach(room => {
          if (room.val().roomname == roomname) {
            found = true;
          }
        })
        if (!found) {
          reference = firebase.database().ref('rooms/Meta').push();
          reference.set({ roomname: roomname, dispRoomname: dispRoomname, ifMeta: ifMeta, instructions: instr });
        }
        reference = firebase.database().ref(`rooms/${bookname}`).push();
        reference.set({ roomname: roomname, dispRoomname: dispRoomname, ifMeta: ifMeta, instructions: instr });
        found = false;
      })
    }
    else {
      reference = firebase.database().ref(`rooms/${bookname}`).push();
      reference.set({ roomname: roomname, dispRoomname: dispRoomname, ifMeta: ifMeta, instructions: instr });
    }
  }

  public static async getIfMeta(roomname: string, bookname: string) {
    var ifMeta: boolean;
    await this.getBoolean(roomname, bookname);

  }

  public static async getBoolean(roomname: string, bookname: string) {
    var rooms = [];
    var flag = true;
    await DataService.getAllRooms(bookname).then(snapshot => {
      rooms = [];
      rooms = snapshotToArray(snapshot);
      for (let i = 0; i < rooms.length; i++) {
        if (rooms[i].roomname == roomname) {
          if (rooms[i].ifMeta == true) {
            this.storeMeta("true");
            flag = false;
            return true;
          }
          else {
            this.storeMeta("false");
          }
        }
      }
      if (flag) {
        this.storeMeta("false");
      }
    })
  }

  public static storeMeta(meta: string) {
    localStorage.setItem('meta', meta)
  }

  public static getMeta(): string {
    return localStorage.getItem('meta');
  }

  public static deleteMetaRoom() {

  }

  public static newURL(roomname: string) {
    var roomUrl: string
    for (let i = 0; i < roomname.length; i++) {
      if (roomname[i] === '#')
        roomUrl = roomUrl + '%2Z';
      else if (roomname[i] === '.')
        roomUrl = roomUrl + '%2X';
      else
        roomUrl = roomUrl + roomname[i];
    }
    localStorage.setItem('roomname', roomname);
    return roomUrl;
  }

  public static async deleteRoom(bookname: string, id: string[], roomnames: string[]) {

    for (let i = 0; i < id.length; i++) {
      if (bookname === 'Meta') {
        console.log("in meta if");
        var rooms = [];
        await DataService.getAllRooms(bookname).then(snapshot => {
          rooms = [];
          rooms = snapshotToArray(snapshot);
        });

        for (let k = 0; k < rooms.length; k++) {
          var name1 = rooms[k].roomname;
          if (name1 === roomnames[i]) {
            var newID = rooms[k].key;
            firebase.database().ref(`rooms/${bookname}/${newID}`).remove().then(function () {
            })
              .catch(function (error) {
              });
            console.log(roomnames);
            for (let j = 0; j < roomnames.length; j++) {
              firebase.database().ref(`chats/Meta/${roomnames[j]}`).remove().then(function () {
              })
                .catch(function (error) {
                });
            }
          }
          window.location.reload();
        }
      }
      else {
        firebase.database().ref(`rooms/${bookname}/${id[i]}`).remove().then(function () {
        })
          .catch(function (error) {
          });

        for (let j = 0; j < roomnames.length; j++) {
          firebase.database().ref(`chats/${roomnames[j]}`).remove().then(function () {
          })
            .catch(function (error) {
            });
        }
        window.location.reload();
      }

    }

  }


  //Handling Chat Data -----------------------------------------------------------------------------------

  public static async getChatsInRoom(roomname: string, bookname: string) {
    return await firebase.database().ref(`chats/${bookname}/${roomname}`).once('value');
  }

  public static getRoomChats(roomname: string, chats: any, bookname: string) {
    this.getIfMeta(roomname, bookname);
    if (this.getMeta() === "true") {
      return firebase.database().ref(`chats/Meta/${roomname}`).on('value', snapshot => {
        snapshot.forEach((childSnapshot: any) => {
          const item = childSnapshot.val();
          item.mediaUrl = childSnapshot.mediaUrl;
          chats.push(item);
        })
      })
    }
    else {
      return firebase.database().ref(`chats/${bookname}/${roomname}`).on('value', snapshot => {
        snapshot.forEach((childSnapshot: any) => {
          const item = childSnapshot.val();
          item.mediaUrl = childSnapshot.mediaUrl;
          chats.push(item);
        })
      })
    }
  }

  public static async updatingChatMessagesForRoom(roomname: string, chats: any[], bookname: string) {
    await this.getIfMeta(roomname, bookname);
    if (this.getMeta() === "true") {
      return firebase.database().ref(`chats/Meta/${roomname}`).on('value', snapshot => {
        chats.splice(0);
        snapshot.forEach((childSnapshot: any) => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          chats.push(item);
        });
      });
    }
    else {
      return firebase.database().ref(`chats/${bookname}/${roomname}`).on('value', snapshot => {
        chats.splice(0);
        snapshot.forEach((childSnapshot: any) => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          chats.push(item);
        });
      });
    }
  }

  /* getRecentChatMessagesForRoom(roomname: string, numMessages: number, bookname: string) {
    DataService.getIfMeta(roomname, bookname);
    if (DataService.getMeta() === "true") {
      return firebase.database().ref(`chats/Meta/${roomname}`).orderByKey().limitToLast(numMessages).on('value', snapshot => {
        return snapshotToArray(numMessages);
      })
    }
    else {
      return firebase.database().ref(`chats/${bookname}/${roomname}`).orderByKey().limitToLast(numMessages).on('value', snapshot => {
        return snapshotToArray(numMessages);
      });
    }
  } */

  public static async sendMessage(roomname: string, date: string, message: string, nickname: string, type: string, bookname: string) {
    const chat = { nickname: '', message: '', date: '', type: '' };
    chat.nickname = nickname;
    chat.date = date
    chat.message = message;
    chat.type = type;

    await this.getIfMeta(roomname, bookname);
    if (this.getMeta() === "true") {
      const newMessage = firebase.database().ref(`chats/Meta/${roomname}`).push();
      newMessage.set(chat);
    } else {
      const newMessage = firebase.database().ref(`chats/${bookname}/${roomname}`).push();
      newMessage.set(chat);
    }
  }

  public static async sendMediaMessage(roomname: string, date: string, event: Event, nickname: string, bookname: string) {
    const chat = { nickname: '', mediaURL: '', date: '', type: '' };
    var filePath = '';
    const storageRef = firebase.storage().ref();

    var mediaType = (event.target as HTMLInputElement).files[0].type;


    if (mediaType.substring(0, 5) == 'video') {
      filePath = `video/${roomname}/${uuidv4()}`;
      chat.type = 'video';
    }
    else if (mediaType.substring(0, 5) == "audio") {
      filePath = `audio/${roomname}/${uuidv4()}`;
      chat.type = 'audio';
    }
    else {
      filePath = `image/${roomname}/${uuidv4()}`;
      chat.type = 'image';
    }

    const uploadTask = await storageRef.child(filePath).put((event.target as HTMLInputElement).files[0]);

    chat.nickname = nickname;
    chat.date = date;
    await storageRef.child(filePath).getDownloadURL().then((url: string) => {
      chat.mediaURL = url;

    }).catch(function (error) {
      alert(error);
    })
    const newMessage = firebase.database().ref(`chats/${bookname}/${roomname}`).push();
    newMessage.set(chat);

    this.getIfMeta(roomname, bookname);
    if (this.getMeta() === "true") {
      const newMessage = firebase.database().ref(`chats/Meta/${roomname}`).push();
      newMessage.set(chat);
    }
    return chat.mediaURL;
  }

  public static async deleteAllChats(roomname: string, chats: any[], bookname: string) {

    var books = [];
    var rooms = [];
    await this.getIfMeta(roomname, bookname);
    if (this.getMeta() === "true") {
      await DataService.getAllBooks().then(snapshot => {
        books = [];
        books = snapshotToArray(snapshot);
      })
      firebase.database().ref(`chats/Meta/${roomname}`).remove().then(function () {
      })
        .catch(function (error) {
        });

      for (let f = 0; f < books.length; f++) {
        await DataService.getAllRooms(books[f].bookname).then(snapshot => {
          rooms = [];
          rooms = snapshotToArray(snapshot);
        })
        for (let j = 0; j < rooms.length; j++) {
          if (rooms[j].roomname === roomname) {
            firebase.database().ref(`chats/${books[f].bookname}/${roomname}`).remove().then(function () {
            })
              .catch(function (error) {
              });
          }
        }
      }
    }
    else {
      firebase.database().ref(`chats/${bookname}/${roomname}`).remove().then(function () {
      })
        .catch(function (error) {
        });
    }

    window.location.reload();
  }

  public static async deleteMessage(roomname: string, id: string[], bookname: string) {

    var books = [];
    var rooms = [];
    await this.getIfMeta(roomname, bookname);
    if (this.getMeta() === "true") {
      for (let i = 0; i < id.length; i++) {
        firebase.database().ref(`chats/Meta/${roomname}/${id[i]}`).remove().then(function () {
        })
          .catch(function (error) {
          });
        firebase.database().ref(`chats/${bookname}/${roomname}/${id[i]}`).remove().then(function () {
        })
          .catch(function (error) {
          });
      }
    }
    else {
      for (let i = 0; i < id.length; i++) {
        firebase.database().ref(`chats/${bookname}/${roomname}/${id[i]}`).remove().then(function () {
        })
          .catch(function (error) {
          });
      }
    }
    window.location.reload();
  }

  //Handling RoomUser Data -----------------------------------------------------------------------------

  public static getRoomusersForRoom(roomname: string, bookname: string) {
    return firebase.database().ref(`roomusers/${bookname}/${roomname}`).once('value');
  }

  public static getOnlineUsersForRoom(roomname: string, bookname: string) {
    return firebase.database().ref(`roomusers/${bookname}/${roomname}/`).orderByChild('status').equalTo('online').once('value');
  }

  public static newGetOnlineUsersForRoom(roomname: string, bookname: string, users: any[]){
    return firebase.database().ref(`roomusers/${bookname}/${roomname}/`)
  }

  public static setRoomuserOnline(roomname: string, nickname: string, bookname: string) {
    return firebase.database().ref(`roomusers/${bookname}/${roomname}/${nickname}`).set({ status: 'online' });
  }

  public static setRoomuserOffline(roomname: string, nickname: string, bookname: string) {
    return firebase.database().ref(`roomusers/${bookname}/${roomname}/${nickname}`).set({ status: 'offline' });
  }

  //Handling User Data -----------------------------------------------------------------------------------

  public static getUser(nickname: string) {
    return firebase.database().ref('users/').orderByKey().equalTo(nickname).once('value');
  }

  //This is not atomic, which may be bad
  public static addUser(nickname: string) {
    return firebase.database().ref(`users/${nickname}`).set(0);
  }

  //Temporarily store book name -------------------------------------------------------------

  public static storeBookname(bookname: string) {
    localStorage.setItem('bookname', bookname)
  }

  public static getBookname(): string {
    return localStorage.getItem('bookname');
  }

  //Handles password data ----------------------------------------------------------------------------------
  public static setPassword(password: string) {
    firebase.database().ref('password').set(password)
  }

  public static getPassword() {
    return firebase.database().ref('password').get();
  }

  // Handles archive data -------------------------------------------------------------------------------------------------
  public static getArchives() {
    return firebase.database().ref('archives').once('value');
  }


  //Handles title data ----------------------------------------------------------------------------------
  public static setTitle(title: string) {
    firebase.database().ref('title').set(title)
  }

  public static getTitle() {
    return firebase.database().ref('title').get();
  }
}



