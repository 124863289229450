import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnyRecord } from 'dns';
import { DataService } from '../data.service';
import { firebase } from '../app.component';

export const snapshotToArray = (snapshot: any) => {
  const returnArr = [];

  snapshot.forEach((childSnapshot: any) => {
    const item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};

@Component({
  selector: 'app-books-list',
  templateUrl: './books-list.component.html',
  styleUrls: ['./books-list.component.css']
})
export class BooksListComponent implements OnInit {
  nickname = '';
  displayedColumns: string[] = ['bookname'];
  books = [];
  isLoadingResults = true;
  booksToDelete: string[] = [];
  regBooks = [];
  archBooks = [];
  archives = [];
  archDispBooks = [];
  archName: string = '';
  changeTitle: FormGroup;
  title: string = '';
  draggingIndex: number;


  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder) {
    if (localStorage.getItem('nickname') != null) {
      this.nickname = localStorage.getItem('nickname');
    }
    else {
      this.nickname = "view-only";
      localStorage.setItem('nickname', 'view-only');
    }
    DataService.getTitle().then(snapshot => {
        if(snapshot.val() === null)
        {
          DataService.setTitle("Book List");
          this.title = "Book List";
        }
        else
        {
          this.title = snapshot.val();
        }
        console.log("title has been set: " + this.title);
      });
    DataService.getAllBooks().then(snapshot => {
      this.books = [];
      this.books = snapshotToArray(snapshot);
      this.isLoadingResults = false;
      for(let i = 0; i < this.books.length; i++){
        if(this.books[i].isArchived){
          this.archBooks[this.archBooks.length] = this.books[i];
        }
        else{
          this.regBooks[this.regBooks.length] = this.books[i];
        }
      }
    })

    DataService.getArchives().then(snapshot => {
      snapshot.forEach((childSnapshot: any) => {
        this.archives.push(childSnapshot.val());
      });
    })
  }

  purpleMode() {
    console.log("clicked")
    document.getElementById('wrapper').style.backgroundColor = "#800080";
    document.getElementById('body').style.backgroundColor = "#d580ff";
    document.getElementById('header').style.color = "#800080";
    document.getElementById('logout-button').style.color = "#800080";
    document.getElementById('logout-button').style.backgroundColor = "#ee82ee";
    document.getElementById('logout-button').style.borderColor = "#ee82ee";
    document.getElementById('edit-button').style.color = "#800080";
    document.getElementById('edit-button').style.backgroundColor = "#ee82ee";
    document.getElementById('edit-button').style.borderColor = "#ee82ee";
    document.getElementById('add-button').style.color = "#800080";
    document.getElementById('add-button').style.backgroundColor = "#ee82ee";
    document.getElementById('add-button').style.borderColor = "#ee82ee";
    document.getElementById('admin-button').style.color = "#800080";
    document.getElementById('admin-button').style.backgroundColor = "#ee82ee";
    document.getElementById('admin-button').style.borderColor = "#ee82ee";
    
    document.getElementById('title').style.color = "#800080";
    document.getElementById('archives-header').style.color = "#800080";
  }

  clickBook(bookname: string) {
    this.router.navigate(['/roomlist', bookname]);
  }

  onDragStart(fromIndex: number): void {
    this.draggingIndex = fromIndex;
  }

  onDragEnter(toIndex: number): void {
    if (this.draggingIndex !== toIndex) {
      this.reorderItem(this.draggingIndex, toIndex);
    }
  }

  private reorderItem(fromIndex: number, toIndex: number): void {
    const itemToBeReordered = this.books.splice(fromIndex, 1)[0];
    this.books.splice(toIndex, 0, itemToBeReordered);
    this.draggingIndex = toIndex;
  }

  onDragEnd(): void {
    for(let i = 0; i < this.books.length; i++)
    {
      const var1 = this.books[i];
      firebase.database().ref(`books/${this.books[i].key}`).remove().then(function () {
      })
        .catch(function (error) {
        });

      firebase.database().ref(`books`).push(var1);
    }
    this.draggingIndex = undefined;
    window.location.reload();
  }

  clickAdmin() {
    this.router.navigate(['/admin']);
  }

  logout(): void {
    localStorage.removeItem('nickname');
    this.router.navigate(['/login']);
  }

  displayRooms(aName: string){
    if(this.archName == aName){
      if(this.archDispBooks.length == 0){
        for(let i = 0; i < this.archBooks.length; i++){
          if(this.archBooks[i].archive == aName){
            this.archDispBooks.push(this.archBooks[i].bookname);
          }
        }
        
      }
      else{
        this.archDispBooks = [];
      }
    }
    else{
      this.archDispBooks = [];
      this.archName = aName;
      for(let i = 0; i < this.archBooks.length; i++){
        if(this.archBooks[i].archive == aName){
          this.archDispBooks.push(this.archBooks[i].bookname);
        }
      }
    }
  }

  getArchiveBooks(arName: any): any[]{
    var tempArray = [];
    for(let i = 0; i < this.archBooks.length; i++){
      if(this.archBooks[i].archive == arName){
        tempArray.push(this.archBooks[i]);
      }
    }
    return tempArray;
  }

  expand(el1: string, el2: string, el3: string){   
    document.getElementById(el1).style.display = 'none';
    document.getElementById(el2).style.display = 'inline-block';
    document.getElementById(el3).style.display = 'block';
  }

  collapse(el1: string, el2: string, el3: string){
    document.getElementById(el1).style.display = 'inline-block';
    document.getElementById(el2).style.display = 'none';
    document.getElementById(el3).style.display = 'none';
  }

  changeTitleSubmit(form: any) {
    document.getElementById('change-password-popup').style.display = 'none';
    console.log(form)
    firebase.database().ref('title').set(form.title)
      .then(snapshot => {
        window.location.reload();
        console.log("title has been changed successfully");
        this.changeTitle = this.formBuilder.group({
          'title': [null, Validators.required]
        })
      })
  }

  displayInstPopup() {
    document.getElementById('change-password-popup').style.display = 'flex';
  }

  closeChangePopup() {
    document.getElementById('change-password-popup').style.display = 'none';
  }

  ngOnInit(): void {

    this.changeTitle = this.formBuilder.group({
      'title': [null, Validators.required]
    })
  }
}
