<div class="example-container">
  <div class="container">
    <button type="button" title="exit room" mat-button matSuffix mat-icon-button aria-label="Exit" (click)="goBack()"
      class="exit-button">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <form class="example-form" [formGroup]="roomForm" (ngSubmit)="onFormSubmit(roomForm.value)">
      <h2>Please Enter New Question</h2>
      <mat-form-field class="example-full-width">
        <mat-label class="input-label">Question</mat-label>
        <textarea matInput placeholder="Enter question" formControlName="roomname" [errorStateMatcher]="matcher"> </textarea>
        <!-- <input type="text" matInput placeholder="Enter question" formControlName="roomname" [errorStateMatcher]="matcher"> -->
        <mat-error>
          <span *ngIf="!roomForm.get('roomname').valid && roomForm.get('roomname').touched">Enter question</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label class="input-label">Instructions (optional)</mat-label>
        <textarea matInput placeholder="Instructions (optional)" formControlName="instructions" value=" "> </textarea>
      </mat-form-field>
      <input type="checkbox" id="meta-check-box" name="check-box" (click)="toggleCheckBox()">
      <label class="checkbox" for="check-box">Do you want this to be a shared chatroom with other books?</label>
      <div class="button-row">
        <button type="submit" title="add room" [disabled]="!roomForm.valid" mat-fab color="primary">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </form>
  </div>
</div>
