import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { firebase } from '../app.component';
import { stringify } from 'querystring';
import { DataService } from '../data.service';

export const snapshotToArray = (snapshot: any) => {
  const returnArr = [];

  snapshot.forEach((childSnapshot: any) => {
      const item = childSnapshot.val();
      item.key = childSnapshot.key;
      returnArr.push(item);
  });

  return returnArr;
};

@Component({
  selector: 'app-roomlist',
  templateUrl: './roomlist.component.html',
  styleUrls: ['./roomlist.component.css']
})
export class RoomlistComponent implements OnInit {
  nickname = '';
  displayedColumns: string[] = ['roomname'];
  rooms = [];
  roomsUndScore = [];
  books = [];
  book: any;
  isLoadingResults = true;
  bookname = this.route.snapshot.params.bookname;
  dispBookname = '';
  imageUrl = '';
  draggingIndex: number;
  
  constructor(private route: ActivatedRoute, private router: Router,  private formBuilder: FormBuilder) {
    this.dispBookname = this.bookname
    for( let i = 0; i < this.bookname.length; i ++){
      this.dispBookname = this.dispBookname.replace('%2X', '.');
      this.dispBookname = this.dispBookname.replace('%2Z', '#')
    }
    
    if(localStorage.getItem('nickname') != null){
      this.nickname = localStorage.getItem('nickname');
    }
    else{
      this.nickname = "view-only";
      localStorage.setItem('nickname', 'view-only');
    }
    DataService.getAllRooms(this.bookname).then(snapshot => {
      this.rooms = [];
      this.rooms = snapshotToArray(snapshot);
      this.isLoadingResults = false;
    })
     DataService.storeBookname(this.bookname);
  }

  toBookList(): void {
    this.router.navigate(['/booklist']);
  }

  clickRoomRow(newroom: string){
    newroom = newroom.replace('#', '%2Z');
    newroom = newroom.replace('.', '%2X');
    this.router.navigate([this.bookname, newroom]);
  }

  toAddRoom(){
    this.router.navigate(['/addroom', this.bookname])
  }

  clickAdmin() {
    this.router.navigate(['/admin']);
  }

  onDragStart(fromIndex: number): void {
    this.draggingIndex = fromIndex;
  }

  onDragEnter(toIndex: number): void {
    if (this.draggingIndex !== toIndex) {
      this.reorderItem(this.draggingIndex, toIndex);
    }
  }

  private reorderItem(fromIndex: number, toIndex: number): void {
    const itemToBeReordered = this.rooms.splice(fromIndex, 1)[0];
    this.rooms.splice(toIndex, 0, itemToBeReordered);
    this.draggingIndex = toIndex;
  }

  onDragEnd(): void {
    for(let i = 0; i < this.rooms.length; i++)
    {
      const var1 = this.rooms[i];
      firebase.database().ref(`rooms/${this.bookname}/${this.rooms[i].key}`).remove().then(function () {
      })
        .catch(function (error) {
        });

      firebase.database().ref(`rooms/${this.bookname}`).push(var1);
    }
    this.draggingIndex = undefined;
    DataService.getAllRooms(this.bookname).then(snapshot => {
      this.rooms = [];
      this.rooms = snapshotToArray(snapshot);
      this.isLoadingResults = false;
    })
  }

  

  ngOnInit(): void {
    DataService.getAllBooks().then(snapshot => {
      this.books = [];
      this.books = snapshotToArray(snapshot);
      this.isLoadingResults = false;

      this.books.forEach(book =>{
        if(this.bookname == book.bookname){
          this.book = book;
        }
      })
    })
    
  }
}
