
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RoomlistComponent } from './roomlist/roomlist.component';
import { AddroomComponent } from './addroom/addroom.component';
import { ChatroomComponent } from './chatroom/chatroom.component';
import { BooksListComponent } from './books-list/books-list.component';
import { AddBookComponent } from './add-book/add-book.component';
import { AdminComponent } from './admin/admin.component';
import { AboutComponent } from './about/about.component';
import { PrintchatsComponent } from './printchats/printchats.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'about', component: AboutComponent },
  { path: 'roomlist/:bookname', component: RoomlistComponent },
  { path: 'addroom/:bookname', component: AddroomComponent },
  { path: ':bookname/:roomname', component: ChatroomComponent },
  { path: 'booklist', component: BooksListComponent },
  { path: 'addbook', component: AddBookComponent },
  { path: 'admin', component: AdminComponent },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {path: 'printchats', component: PrintchatsComponent}
];

@NgModule({
  //imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  imports: [RouterModule.forRoot(routes, { useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
