import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Location } from '@angular/common';
import { DataService } from '../data.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-addroom',
  templateUrl: './addroom.component.html',
  styleUrls: ['./addroom.component.css']
})
export class AddroomComponent implements OnInit {
  roomForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  bookname = '';
  ifMeta = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location,
  ) {
    this.bookname = this.route.snapshot.params.bookname;
    if(localStorage.getItem('nickname') != null){
    }
    else{
      localStorage.setItem('nickname', 'view-only');
    }
  }

  ngOnInit(): void {
    this.roomForm = this.formBuilder.group({
      'roomname': [null, Validators.required],
      'instructions': [null]
    });
  }

  goBack() {
    this.location.back();
  }

  onFormSubmit(form: any) {
    const room = form;
    var roomname: string = room.roomname;
    for(let i = 0; i < room.roomname.length; i++){
      roomname = roomname.replace('\n', '');
    }
      DataService.getRoom(roomname, this.bookname).then(snapshot => {
        DataService.addRoom(roomname, this.bookname, this.ifMeta, room.instructions);
        this.router.navigate(['/roomlist', this.bookname]);
      })
  }

  toggleCheckBox() {
    this.ifMeta = !this.ifMeta;
  }
}
