<div class="example-container mat-elevation-z8">
  <div id="image-container">
    <img class="book-image" src="{{imageUrl}}">
  </div>
  <meta name="viewport" content="width=device-width initial-scale=1.0">
  <h3>
    <button mat-flat-button title="logout" (click)="toBookList()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    &nbsp;{{nickname}}
  </h3>
  <h2>Questions for {{dispBookname}}</h2>

  <div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <div class="button-row">
    <button id="add-button" *ngIf="(nickname.match('admin') || nickname.match('Admin')) && !book.isArchived"
      mat-flat-button color="primary" title="add room" (click)="toAddRoom()">
      <mat-icon>add</mat-icon>
    </button>
    <button class="admin-button" *ngIf="nickname.match('admin') || nickname.match('Admin')" mat-flat-button
      title="adminPage" (click)="clickAdmin()"> Admin Page</button>
  </div>
  <div class="mat-elevation-z8" *ngIf="(!nickname.match('admin') && !nickname.match('Admin'))">
    <table mat-table [dataSource]="rooms" class="example-table" matSort matSortActive="roomname" matSortDisableClear
      matSortDirection="asc">
      <ng-container matColumnDef="roomname">
        <th mat-header-cell *matHeaderCellDef>Questions</th>
        <td mat-cell *matCellDef="let row">
          {{row.dispRoomname}}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr id="room-button" mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="clickRoomRow(row.roomname)"></tr>
      <th mat-header-cell *matHeaderCellDef>Room Name</th>
    </table>
  </div>

  <div class="mat-elevation-z8" *ngIf="(nickname.match('admin') || nickname.match('Admin'))">
    <table mat-table [dataSource]="rooms" class="example-table" matSort matSortActive="roomname" matSortDisableClear
      matSortDirection="asc">
      <ng-container matColumnDef="roomname">
        <th mat-header-cell *matHeaderCellDef>Questions</th>
        <td class="row-name" mat-cell *matCellDef="let row">
          {{row.dispRoomname}}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr id="room-button" mat-row *matRowDef="let row; let index = index; columns: displayedColumns;"
        (click)="clickRoomRow(row.roomname)" draggable="true" (dragstart)="onDragStart(index)" (dragenter)="onDragEnter(index)" (dragend)="onDragEnd()"></tr>
      <th mat-header-cell *matHeaderCellDef>Room Name</th>
    </table>
  </div>