import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, MaxLengthValidator, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { firebase } from '../app.component';

export const snapshotToArray = (snapshot: any) => {
  const returnArr = [];

  snapshot.forEach((childSnapshot: any) => {
    const item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};



@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  nickname = '';
  displayedColumns: string[] = ['bookname'];
  rooms = [];
  books = [];
  archBooks = [];
  tempBooks = [];
  clicked = false;
  isLoadingResults = true;
  bookname = '';
  imageUrl = '';
  bookIds = [];
  roomIds = [];
  roomNames = [];
  roomIdsBook = [];
  delBooks = [];
  oldPsw = '';
  pswForm: FormGroup;
  selectArchForm: FormGroup;
  addNewArch: FormGroup;
  deleteArch: FormGroup;
  archives = [];
  valid = false;
  archivesToDel = [];
  roomMap: Map<string, any> = new Map([]);
  delRooms = [];
  booksToArch = [];
  booksToUnarch = [];
  metaRooms = [];
  delMetaRooms = [];

  n
  constructor(private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder) {
    if (localStorage.getItem('nickname') != null) {
      this.nickname = localStorage.getItem('nickname');
    }
    else {
      this.nickname = "view-only";
      localStorage.setItem('nickname', 'view-only');
    }

    this.generateBooks();

    firebase.database().ref('archives').once('value').then(snapshot => {
      this.archives = [];
      var selectBox = document.getElementById('archive-select') as HTMLSelectElement;
      var optionList = selectBox.options;
      snapshot.forEach((childSnapshot: any) => {
        this.archives.push(childSnapshot);

      });

      optionList.add(new Option('Pick an archive to add to', '', true))

      this.archives.forEach(option =>
        optionList.add(
          new Option(option.val())
        )
      )
    })

    this.generateMetaRooms();
  }

  generateMetaRooms() {
    firebase.database().ref('rooms/Meta').once('value').then(snapshot => {
      snapshot.forEach((room: any) => {
        var found = false;
        for (let i = 0; i < this.metaRooms.length; i++) {
          if (room.val().roomname == this.metaRooms[i].roomname) {
            found = true
          }
        }
        if (!found) {
          const item = room.val();
          item.key = room.key
          this.metaRooms.push(item);
        }
        found = false;
      })
    })
  }

  generateBooks() {
    firebase.database().ref('books/').once('value').then(snapshot => {
      this.tempBooks = [];
      this.books = [];
      this.archBooks = [];
      this.tempBooks = snapshotToArray(snapshot);
      this.isLoadingResults = false;
      for (let i = 0; i < this.tempBooks.length; i++) {
        if (!this.tempBooks[i].isArchived) {
          this.books.push(this.tempBooks[i]);
        } else {
          this.archBooks.push(this.tempBooks[i]);
        }
      }
      this.createRoomMap();
    })
  }

  createRoomMap() {
    for (let i = 0; i < this.books.length; i++) {
      firebase.database().ref(`rooms/${this.books[i].bookname}`).once('value').then(snapshot => {
        var tempArray = [];
        snapshot.forEach((childSnapshot: any) => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          tempArray.push(item)
        })
        this.roomMap.set(this.books[i].bookname, tempArray)
      })
    }
  }

  getArchives() {
    firebase.database().ref('archives').once('value').then(snapshot => {
      this.archives = [];
      var selectBox = document.getElementById('archive-select') as HTMLSelectElement;

      document.getElementById("archive-select").innerHTML = "";
      var optionList = selectBox.options;

      snapshot.forEach((childSnapshot: any) => {
        this.archives.push(childSnapshot);

      });

      optionList.add(new Option('Pick an archive to add to', '', true))

      this.archives.forEach(option =>
        optionList.add(
          new Option(option.val())
        )
      )
    })
  }

  toBookList(): void {
    this.router.navigate(['/booklist']);
  }

  clickRoomRow(roomname: string) {
    DataService.setRoomuserOnline(roomname, this.nickname, this.bookname);
    this.router.navigate(['/chatroom', roomname]);
  }

  toAddRoom() {
    this.router.navigate(['/addroom', this.bookname])
  }

  clickBook(bookname: string) {
    if (this.bookname == bookname) {
      if (this.rooms.length == 0) {
        DataService.getAllRooms(bookname).then(snapshot => {
          this.rooms = [];
          this.rooms = snapshotToArray(snapshot);
          this.isLoadingResults = false;
        })
        DataService.storeBookname(this.bookname);
      }
      else {
        this.rooms = [];
      }
    }
    else {
      this.bookname = bookname;
      DataService.getAllRooms(bookname).then(snapshot => {
        this.rooms = [];
        this.rooms = snapshotToArray(snapshot);
        this.isLoadingResults = false;
      })
      DataService.storeBookname(this.bookname);
    }
  }

  toggleCheckedBookDel(book) {
    this.bookname = book.bookname
    for (let i = 0; i < this.bookIds.length; i++) {
      if (book.key == this.bookIds[i]) {
        this.bookIds.splice(i, 1);
        this.delBooks.splice(i, 1);
        var tempRooms = [];
        DataService.getAllRooms(book.bookname).then(snapshot => {
          tempRooms = snapshotToArray(snapshot).map(getName);
          function getName(item) {
            return item.roomname;
          }
          var tempRoomsIndex = [];
          for (let j = 0; j < this.roomNames.length; j++) {

            for (let n = 0; n < tempRooms.length; n++) {
              if (this.roomNames[j][n] == tempRooms[n]) {
                tempRoomsIndex[tempRoomsIndex.length] = n;
              }
            }
          }
          for (let m = 0; m < tempRoomsIndex.length; m++) {
            this.roomNames.splice(tempRoomsIndex[m], 1);
            this.roomIdsBook.splice(tempRoomsIndex[m], 1);
          }
        })
        return;
      }
    }
    this.bookIds[this.bookIds.length] = book.key;
    this.delBooks[this.delBooks.length] = book;
    DataService.getAllRooms(book.bookname).then(snapshot => {
      var roomsSelected = snapshotToArray(snapshot);
      this.roomNames.push(roomsSelected.map(getName));
      this.roomIdsBook.push(roomsSelected.map(getId));
      function getName(item) {
        return item.roomname;
      }
      function getId(item) {
        return item.key
      }
    });
  }

  toggleCheckedRoom(room, book) {
    var roomObj = { roomname: room.roomname, key: room.key, bookname: book.bookname }
    for (let i = 0; i < this.delRooms.length; i++) {
      if (room.key == this.delRooms[i].key) {
        this.delRooms.splice(i, 1);
        return;
      }
    }
    this.delRooms.push(roomObj)
  }

  toggleCheckedMetaRoom(room) {
    var roomObj = { roomname: room.roomname, key: room.key, bookname: 'Meta' }
    for (let i = 0; i < this.delMetaRooms.length; i++) {
      if (room.key == this.delMetaRooms[i].key) {
        this.delMetaRooms.splice(i, 1);
        return;
      }
    }
    this.delMetaRooms.push(roomObj)
  }

  toggleArchBook(book) {
    for (let i = 0; i < this.booksToArch.length; i++) {
      if (book.key == this.booksToArch[i].key) {
        this.booksToArch.splice(i, 1);
        return;
      }
    }
    this.booksToArch.push(book)
  }

  deleteBooks(id: string[]) {
    DataService.deleteBook(this.roomIdsBook, this.roomNames, this.delBooks);
    this.generateBooks();
  }

  deleteRooms() {
    this.delRooms.forEach(room => {
      firebase.database().ref(`rooms/${room.bookname}/${room.key}`).remove();
    })
    this.delMetaRooms.forEach(room => {
      firebase.database().ref(`rooms/Meta/${room.key}`).remove();
      for (let i = 0; i < this.books.length; i++) {
        firebase.database().ref(`rooms/${this.books[i].bookname}`).once('value')
          .then(snapshot => {
            var tempArray = [];
            tempArray = snapshotToArray(snapshot);
            for (let j = 0; j < tempArray.length; j++) {
              if(tempArray[j].ifMeta === true && tempArray[j].roomname === room.roomname)
              {
                firebase.database().ref(`rooms/${this.books[i].bookname}/${tempArray[j].key}`).remove();
              }
            }
          })
      }
    })
    this.roomMap.clear();
    this.createRoomMap();
    window.location.reload();
  }



  changePassword(form: any) {
    if (this.oldPsw != form.oldPassword) {
      alert("Old password doesn't match submitted old password")
      return;
    }

    if (form.newPassword != form.newPasswordConf) {
      alert("New passwords don't match");
      return;
    } else {
      DataService.setPassword(form.newPassword);
      DataService.getPassword();
      this.pswForm = this.formBuilder.group({
        'oldPassword': [null, Validators.required],
        'newPassword': [null, Validators.required],
        'newPasswordConf': [null, Validators.required]
      });
    }
  }

  addToArchive(form: any) {
    var archiveName = form.archiveName;
    if (archiveName != null && archiveName != '' && archiveName != ' ') {
      for (let i = 0; i < this.booksToArch.length; i++) {
        var book = this.booksToArch[i]
        firebase.database().ref(`books/${this.booksToArch[i].key}`).remove();
        const reference = firebase.database().ref('books/').push();
        reference.set({ bookname: book.bookname, dispBookname: book.dispBookname, imageUrl: book.imageUrl, author: book.author, isArchived: true, archive: archiveName });
        for (let j = 0; j < this.books.length; j++) {
          console.log(this.booksToArch)
          if (this.booksToArch[i].bookname = this.books[j].bookname) {
            console.log(this.books[j])
            this.books.splice(j, 1)

          }
        }
        this.archBooks.push(this.booksToArch[i]);
      }
    }

    this.booksToArch = [];
    this.generateBooks();
  }

  addNewArchive(form: any) {
    firebase.database().ref('archives').push(form.archiveName);
    this.addNewArch = this.formBuilder.group({
      'archiveName': [null, Validators.required]
    })
    this.getArchives();
  }

  deleteArchives() {
    for (let i = 0; i < this.archivesToDel.length; i++) {
      firebase.database().ref(`archives/${this.archivesToDel[i].key}`).remove();
    }

    for (let i = 0; i < this.archBooks.length; i++) {
      firebase.database().ref(`books/${this.archBooks[i].key}`).remove();
    }
    this.archivesToDel = [];
    this.deleteArch = this.formBuilder.group({
      'archivesToDelete': [null, Validators.required]
    })
    this.getArchives();
  }

  toggleArchive(archiveName: string) {
    if (this.archivesToDel.includes(archiveName)) {
      for (let i = 0; i < this.archivesToDel.length; i++) {
        if (this.archivesToDel[i] == archiveName) {
          this.archivesToDel.splice(i, 1);
        }
      }
    }
    else {
      this.archivesToDel.push(archiveName);
    }
  }

  unArchiveBooks() {
    for (let i = 0; i < this.booksToUnarch.length; i++) {
      var book = this.booksToUnarch[i];
      firebase.database().ref(`books/${book.key}`).remove();
      const reference = firebase.database().ref('books/').push();
      reference.set({ bookname: book.bookname, dispBookname: book.dispBookname, imageUrl: book.imageUrl, author: book.author, isArchived: false, archive: '' });
      firebase.database().ref(`rooms/${book.bookname}`).once('value').then(snapshot => {
        snapshot.forEach(room => {
          var found = false;
          for (let j = 0; j < this.metaRooms.length; j++) {
            if (room.val().isMeta && room.val().roomname == this.metaRooms[i].roomname) {
              found = true;
            }
          }
          if ((room.val().ifMeta && !found) || this.metaRooms.length == 0) {
            firebase.database().ref('rooms/Meta').push().set({ ifMeta: true, instructions: room.val().instructions, roomname: room.val().roomname });
          }
        })
      })
    }
    this.booksToUnarch = [];
    this.generateBooks();
  }

  getBooksInArchive(arName: string): any[] {
    var booksArr = [];
    for (let i = 0; i < this.archBooks.length; i++) {
      if (this.archBooks[i].archive == arName) {
        booksArr.push(this.archBooks[i])
      }
    }
    return booksArr;
  }

  toggleUnarchiveBooks(book: any) {
    for (let i = 0; i < this.booksToUnarch.length; i++) {
      if (book.key == this.booksToUnarch[i].key) {
        this.booksToUnarch.splice(i, 1);
        return;
      }
    }
    this.booksToUnarch.push(book)
  }

  recoverPassword() {

  }

  myFunc() {
    alert("THIS BUTTON WAS CLICKED")
  }

  toggleHeader(el1: string, el2: string, el3: string) {
    var ex = document.getElementById(el1);
    if (window.getComputedStyle(ex).display == 'none') {
      document.getElementById(el1).style.display = 'inline-block';
      document.getElementById(el2).style.display = 'none';
      document.getElementById(el3).style.display = 'none';
    }
    else {
      document.getElementById(el1).style.display = 'none';
      document.getElementById(el2).style.display = 'inline-block';
      document.getElementById(el3).style.display = 'block';
    }
  }


  // Open and collapse methods for all Admin functions
  expand(el1: string, el2: string, el3: string) {
    document.getElementById(el1).style.display = 'none';
    document.getElementById(el2).style.display = 'inline-block';
    document.getElementById(el3).style.display = 'block';
  }

  collapse(el1: string, el2: string, el3: string) {
    document.getElementById(el1).style.display = 'inline-block';
    document.getElementById(el2).style.display = 'none';
    document.getElementById(el3).style.display = 'none';
  }

  ngOnInit(): void {
    DataService.getPassword().then(snapshot => {
      this.oldPsw = snapshot.val();
    })

    this.pswForm = this.formBuilder.group({
      'oldPassword': [null, Validators.required],
      'newPassword': [null, Validators.required],
      'newPasswordConf': [null, Validators.required]
    });

    this.selectArchForm = this.formBuilder.group({
      'archiveName': [null, Validators.required]
    })
    this.addNewArch = this.formBuilder.group({
      'archiveName': [null, Validators.required]
    })
    this.deleteArch = this.formBuilder.group({
      'archivesToDelete': [null, Validators.required]
    })
  }
}